<template>
  <div>
    <settings-table-sidebar />
    <import-leads-sidebar />
    <new-lead-sidebar />
    <new-appointment-sidebar />
    <comment-sidebar />
    <!-- Contadores -->
    <leads-totalizer
      :totalizer="totalizer"
      :filterStatus="filterByStatus"
      :openNewLeadSidebar="editCustomer"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- Filtros -->
      <leads-filters ref="filters" :spinnerActive="loading" :search="search" />
      <div class="m-md-2 mb-2 mx-2 mt-0">
        <!-- Table Top -->
        <b-row class="search-buttons-section">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b-button
              id="lead-import"
              class="btn-icon mr-1"
              variant="flat"
              style="padding: 0; color: #f26237"
              @click="import_leads()"
            >
              <feather-icon icon="FilePlusIcon" />
            </b-button>
            <b-tooltip target="lead-import" triggers="hover">
              Importar leads
            </b-tooltip>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="8">
            <div
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0 flex-wrap-reverse flex-md-wrap"
            >
              <b-button
                class="mr-0 mr-md-1 mb-md-0 mb-1 search-button"
                variant="outline-primary"
                @click="settings_table_modal"
              >
                <span class="align-middle">Personalizar tabela</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-0 mr-md-1 mb-md-0 mb-1 search-button"
                :disabled="loading"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading"
                class="search-button mb-md-0 mb-1"
              >
                <b-spinner v-if="loading" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative text-center lead-table"
        :items="items"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontrados leads para esta busca"
        :busy="loading"
        :primary-key="'customer_id'"
        v-sortable="sortableOptions"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: Prioridade -->
        <template #head(sequence)="data">
          <div class="text-center">
            <b-button
              id="lead-reorder"
              class="btn-icon mr-1"
              variant="flat"
              style="padding: 0"
              @click="reorder"
            >
              <feather-icon class="text-body" icon="RefreshCwIcon" />
            </b-button>
            <b-tooltip target="lead-reorder" triggers="hover">
              Reordenar
            </b-tooltip>

            <span id="sequence-header">{{ data.label.toUpperCase() }}</span>
            <b-tooltip target="sequence-header" triggers="hover">
              Prioridade
            </b-tooltip>
          </div>
        </template>
        <template #cell(sequence)="data">
          <div class="text-wrap">
            <b-form-input
              class="text-center"
              @blur="setManualSequence(data)"
              v-model="data.item.sequence"
              placeholder="#"
              style="width: 55px"
            />
          </div>
        </template>

        <!-- Column: Nome e telefone -->
        <template #cell(customer_name)="data">
          <div class="name-content">
            <b-badge
              variant="lead-name"
              class="name"
              @click="goToLeadDetails(data.item.customer_id)"
            >
              <div
                class="align-text-right text-capitalize text-wrap"
                style="margin-bottom: 4px"
              >
                {{ data.item.customer_name }}
              </div>
              <div v-if="data.item.cell_phone" class="align-text-top phone">
                {{ data.item.cell_phone | formatPhone }}
              </div>
            </b-badge>
            <b-badge
              variant="lead-name"
              class="whats"
              @click="openWhatsapp(data.item.cell_phone)"
            >
              <img src="~@/assets/images/svg/icn_whats.svg" width="15" />
            </b-badge>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(customer_status_name)="data">
          <div class="text-center">
            <b-badge
              pill
              variant="hot-user"
              v-if="data.item.customer_status_id == status.CUSTOMER_STATUS_HOT"
            >
              <img src="~@/assets/images/svg/icn_hot_active.svg" width="10" />
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="cold-user"
              v-else-if="
                data.item.customer_status_id == status.CUSTOMER_STATUS_COLD
              "
            >
              <img src="~@/assets/images/svg/icn_cold_active.svg" width="10" />
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="disinterested-user"
              v-else-if="
                data.item.customer_status_id ==
                status.CUSTOMER_STATUS_DISINTERESTED
              "
            >
              <img
                src="~@/assets/images/svg/icn_disinterested_active.svg"
                width="12"
              />
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="scheduled_interview-user"
              v-else-if="
                data.item.customer_status_id == status.CUSTOMER_STATUS_EA
              "
            >
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="done_interview-user"
              v-else-if="
                data.item.customer_status_id == status.CUSTOMER_STATUS_EF
              "
            >
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="wait_contract_payment-user"
              v-else-if="
                data.item.customer_status_id ==
                status.CUSTOMER_STATUS_WAIT_CONTRACT_PAYMENT
              "
            >
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge pill variant="undefined-user" v-else>
              {{ data.item.customer_status_name }}
              <span v-if="!data.item.customer_status_name">
                A classificar
              </span>
            </b-badge>
          </div>
        </template>

        <!-- Column: Lembrar / Data de ligação -->
        <template #cell(call_reminder)="data">
          <div class="text-wrap">
            <b-form-input
              @blur="
                saveCallReminder(data.item.call_reminder, data.item.customer_id)
              "
              class="form-control"
              type="datetime-local"
              v-model="data.item.call_reminder"
            />
          </div>
        </template>

        <!-- Column: Poupa -->
        <template #cell(monthly_savings)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.monthly_savings | toCurrency
            }}</span>
          </div>
        </template>

        <!-- Column: Objetivo -->
        <template #cell(goals)="data">
          <div
            v-for="(goal, index) in splitGoals(data.item.goals)"
            :key="`goal-${index}`"
          >
            <details>
              <summary style="width: max-content; font-weight: bold">
                {{ goal | leadGoalTitle }}
              </summary>
              <p style="">{{ goal | leadGoal }}</p>
            </details>
          </div>
        </template>

        <!-- Column: Consultor responsável -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.consultant_name
            }}</span>
          </div>
        </template>

        <!-- Column: Aniversário -->
        <template #cell(anniversary_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.anniversary_date }}</span>
          </div>
        </template>

        <!-- Column: Última reunião -->
        <template #cell(last_interview_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.last_interview_date | dateTime
            }}</span>
          </div>
        </template>

        <!-- Column: Atualizado -->
        <template #cell(last_status_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.last_status_date | dateTime
            }}</span>
          </div>
        </template>

        <!-- Column: Data formatada -->
        <template #cell(created_at)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.created_at | dateTime
            }}</span>
          </div>
        </template>

        <!-- Column: Data formatada -->
        <template #cell(customer_reference_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.customer_reference_name }}
            </span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-wrap text-right">
            <b-button-group>
              <template v-if="canChangeStatus(data.item.customer_status_id)">
                <b-button
                  :id="`lead-customer-hot` + data.item.customer_id"
                  class="btn-icon mr-1"
                  variant="flat"
                  style="padding: 0"
                  @click="setStatus(data.item, status.CUSTOMER_STATUS_HOT)"
                >
                  <!-- quente ativo -->
                  <img
                    v-if="
                      data.item.customer_status_id == status.CUSTOMER_STATUS_HOT
                    "
                    class="img_action"
                    src="~@/assets/images/svg/icn_hot_active.svg"
                  />
                  <!-- quente desabilitado -->
                  <img
                    v-if="
                      data.item.customer_status_id != status.CUSTOMER_STATUS_HOT
                    "
                    class="img_action"
                    src="~@/assets/images/svg/icn_hot_disable.svg"
                  />
                </b-button>
                <b-tooltip
                  :target="`lead-customer-hot` + data.item.customer_id"
                  triggers="hover"
                >
                  Esquentar
                </b-tooltip>
                <b-button
                  :id="`lead-customer-cold` + data.item.customer_id"
                  class="btn-icon mr-1"
                  variant="flat"
                  style="padding: 0"
                  @click="setStatus(data.item, status.CUSTOMER_STATUS_COLD)"
                >
                  <!-- frio ativo -->
                  <img
                    v-if="
                      data.item.customer_status_id ==
                      status.CUSTOMER_STATUS_COLD
                    "
                    class="img_action"
                    src="~@/assets/images/svg/icn_cold_active.svg"
                  />
                  <!-- frio desabilitado -->
                  <img
                    v-if="
                      data.item.customer_status_id !=
                      status.CUSTOMER_STATUS_COLD
                    "
                    class="img_action"
                    src="~@/assets/images/svg/icn_cold_disable.svg"
                  />
                </b-button>
                <b-tooltip
                  :target="`lead-customer-cold` + data.item.customer_id"
                  triggers="hover"
                >
                  Esfriar
                </b-tooltip>
                <b-button
                  :id="`lead-customer-disinterested` + data.item.customer_id"
                  class="btn-icon mr-1"
                  variant="flat"
                  style="padding: 0"
                  @click="
                    setStatus(data.item, status.CUSTOMER_STATUS_DISINTERESTED)
                  "
                >
                  <!-- desinteressado ativo -->
                  <img
                    v-if="
                      data.item.customer_status_id ==
                      status.CUSTOMER_STATUS_DISINTERESTED
                    "
                    class="img_action"
                    src="~@/assets/images/svg/icn_disinterested_active.svg"
                  />
                  <!-- desinteressado desabilitado -->
                  <img
                    v-if="
                      data.item.customer_status_id !=
                      status.CUSTOMER_STATUS_DISINTERESTED
                    "
                    class="img_action"
                    src="~@/assets/images/svg/icn_disinterested_disable.svg"
                  />
                </b-button>
                <b-tooltip
                  :target="
                    `lead-customer-disinterested` + data.item.customer_id
                  "
                  triggers="hover"
                >
                  Desinteressado
                </b-tooltip>
              </template>
              <template v-if="data.item.can_create_appointment">
                <b-button
                  :id="`lead-customer-appointment` + data.item.customer_id"
                  class="btn-icon mr-1"
                  variant="flat"
                  style="padding: 0"
                  @click="createAppointment(data.item.customer_id)"
                >
                  <img
                    class="img_action"
                    src="~@/assets/images/svg/icn_calendar.svg"
                  />
                </b-button>
                <b-tooltip
                  :target="`lead-customer-appointment` + data.item.customer_id"
                  triggers="hover"
                >
                  Agendar reunião
                </b-tooltip>
              </template>
              <b-button
                :id="`lead-customer-edit` + data.item.customer_id"
                class="btn-icon mr-1"
                variant="flat"
                style="padding: 0"
                @click="editCustomer(data.item.customer_id)"
              >
                <img
                  class="img_action"
                  src="~@/assets/images/svg/icn_edit.svg"
                />
              </b-button>
              <b-tooltip
                :target="`lead-customer-edit` + data.item.customer_id"
                triggers="hover"
              >
                Editar
              </b-tooltip>
              <b-button
                :id="`lead-customer-comment` + data.item.customer_id"
                class="btn-icon mr-1"
                variant="flat"
                style="padding: 0; color: white"
                @click="addComment(data.item.customer_id)"
              >
                <feather-icon class="text-body" icon="MessageSquareIcon" />
              </b-button>
              <b-tooltip
                :target="`lead-customer-comment` + data.item.customer_id"
                triggers="hover"
              >
                Adicionar comentário
              </b-tooltip>
              <b-button
                :id="`lead-customer-delete` + data.item.customer_id"
                class="btn-icon"
                variant="flat"
                style="padding: 0; color: white"
                @click="deleteLeadCustomer(data.item.customer_id)"
              >
                <feather-icon class="text-body" icon="TrashIcon" />
              </b-button>
              <b-tooltip
                :target="`lead-customer-delete` + data.item.customer_id"
                triggers="hover"
              >
                Remover
              </b-tooltip>
            </b-button-group>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BCardText,
  BMediaAside,
  BMediaBody,
  BCardTitle,
  BButtonGroup,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { LEAD_TYPE_PCL } from "@/constants/lead";
import SettingsTableSidebar from "../components/SettingsTableSidebar";
import ImportLeadsSidebar from "../components/ImportLeadsSidebar";
import LeadsTotalizer from "../components/LeadsTotalizer";
import LeadsFilters from "../components/LeadsListFilters";
import NewLeadSidebar from "../components/NewLeadSidebar";
import NewAppointmentSidebar from "@/modules/appointment/components/NewAppointmentSidebar";
import CommentSidebar from "@/modules/shared/components/CommentSidebar";
import { sortable } from "@/libs/sortable";
import { getRangeDates } from "@/helpers/date_picker";
import * as appointmentTypes from "@/modules/appointment/store/types";
import * as structureOptions from "@/constants/structure_options";
import * as customerStatus from "@/constants/customers_status";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardText,
    BCardTitle,
    BMediaAside,
    BMediaBody,
    BButtonGroup,
    BTooltip,
    SettingsTableSidebar,
    ImportLeadsSidebar,
    vSelect,
    LeadsTotalizer,
    LeadsFilters,
    NewLeadSidebar,
    NewAppointmentSidebar,
    CommentSidebar,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  directives: {
    sortable,
  },
  data() {
    return {
      loading: false,
      leadType: LEAD_TYPE_PCL,
      appifyUrl: process.env.VUE_APP_URL,
      currentPage: 1,
      itemsPerPage: 1,
      searchQuery: "",
      ic_bt_fire: 1,
      structureOption: structureOptions.OWN,
      sortField: null,
      order: undefined,
    };
  },
  computed: {
    ...mapGetters({
      leadsSearchResult: types.LEADS_SEARCH_RESULT,
      leadsSearchTotalizers: types.LEADS_SEARCH_TOTALIZERS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      settingsTableCustomerLeads: types.SETTINGS_TABLE_CUSTOMERS_LEADS,
      tableColumns: types.LEADS_TABLE_COLUMNS,
    }),
    items: function () {
      return this.leadsSearchResult?.data || [];
    },
    totalItems: function () {
      return this.leadsSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.leadsSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.leadsSearchResult?.to || 0;
    },
    totalizer: function () {
      if (this.leadsSearchTotalizers) {
        var totalizerObj = {};
        this.leadsSearchTotalizers.forEach((item) => {
          totalizerObj[item.customer_status_id] = item.total;
        });
        var sumTotal = Object.values(totalizerObj).reduce(
          (acc, curr) => acc + curr,
          0
        );
        totalizerObj.total_sum = sumTotal;
        return totalizerObj || {};
      } else {
        return {};
      }
    },
    status: function () {
      return customerStatus;
    },
    sortableOptions: function () {
      return {
        chosenClass: "is-selected",
        onEnd: this.dragDropChange,
        onMove: this.canDrag,
      };
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.getSettingsTableCustomersLeads()
      .then((response) => {
        this.hideTableColumns();
        this.search();
      })
      .catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as configurações do resultado da busca. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    ...mapActions({
      searchLeads: types.SEARCH_LEADS,
      openNewLeadSidebar: types.OPEN_NEW_LEAD_SIDEBAR,
      openSettingsTableSidebar: types.OPEN_SETTINGS_TABLE_SIDEBAR,
      openImportLeadsSidebar: types.OPEN_IMPORT_LEADS_SIDEBAR,
      getSettingsTableCustomersLeads: types.GET_SETTINGS_TABLE_CUSTOMERS_LEADS,
      setCustomerLeadStatus: types.SET_CUSTOMER_LEAD_STATUS,
      setCustomerLeadSequence: types.SET_CUSTOMER_LEAD_SEQUENCE,
      setCallReminder: types.SET_CALL_REMINDER,
      deleteLead: types.DELETE_LEAD,
      openNewAppointmentSidebar: appointmentTypes.OPEN_NEW_APPOINTMENT_SIDEBAR,
      reorderLeadTable: types.REORDER_LEAD_TABLE,
      openCommentSidebar: sharedTypes.OPEN_COMMENT_SIDEBAR,
      saveComment: sharedTypes.SAVE_CUSTOMER_COMMENT,
    }),
    ...mapMutations({
      hideTableColumns: types.HIDE_COLUMNS,
      mutateDisableSeqColumn: types.MUTATE_DISABLE_SEQ_COLUMN,
    }),
    settings_table_modal() {
      this.openSettingsTableSidebar({
        currentPage: this.currentPage ? this.currentPage : 1,
      });
    },
    import_leads() {
      this.openImportLeadsSidebar({});
    },
    search(currentPage) {
      if (!this.$refs.filters) return;
      this.loading = true;
      this.currentPage = Number.isInteger(currentPage) ? currentPage : 1;
      const {
        name,
        createdAt,
        goal,
        origin,
        franchise,
        status,
        occupation,
        income,
        recommendedBy,
        callReminder,
        structureOption,
        consultant,
        monthlySavings,
        consultantsActive,
        campaign,
      } = this.$refs.filters;
      this.structureOption = structureOption;
      const call_reminder = getRangeDates(callReminder);
      const created_at = getRangeDates(createdAt);
      this.searchLeads({
        name,
        created_at_start: created_at.start,
        created_at_end: created_at.end,
        goal,
        origin,
        franchise,
        occupation,
        status,
        income,
        customer_reference: recommendedBy,
        call_reminder_start: call_reminder.start,
        call_reminder_end: call_reminder.end,
        structure_option: structureOption,
        monthly_savings: monthlySavings,
        consultant,
        consultants_active: consultantsActive,
        campaign,
        order: this.order,
        limit: this.itemsPerPage,
        currentPage: this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os leads. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
      if (!this.canDrag(null)) {
        this.mutateDisableSeqColumn({ hide: true });
      } else {
        this.mutateDisableSeqColumn({ hide: false });
      }
    },
    canChangeStatus(customer_status_id) {
      if (
        customer_status_id == customerStatus.CUSTOMER_STATUS_EF ||
        customer_status_id == customerStatus.CUSTOMER_STATUS_EP ||
        customer_status_id == customerStatus.CUSTOMER_STATUS_PA ||
        customer_status_id == customerStatus.CUSTOMER_STATUS_PF ||
        customer_status_id == customerStatus.CUSTOMER_STATUS_NA ||
        customer_status_id == customerStatus.CUSTOMER_STATUS_NF
      ) {
        return false;
      }
      return true;
    },
    setStatus(data_current, new_status) {
      if (data_current.customer_status_id == new_status) {
        new_status = customerStatus.CUSTOMER_STATUS_UNDEFINED;
      }
      if (this.canChangeStatus(data_current.customer_status_id)) {
        this.setLeadStatus(data_current.customer_id, new_status);
      }
    },
    setLeadStatus(customer_id, status_id) {
      this.setCustomerLeadStatus({
        customer_id,
        status_id,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Status atualizado com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.search(this.currentPage);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao atualizar o status. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    openWhatsapp(cell_phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + cell_phone, "_blank").focus();
      });
    },
    editCustomer(id) {
      this.openNewLeadSidebar({
        id,
        leadType: LEAD_TYPE_PCL,
        saveAction: () => {
          this.search(this.currentPage);
        },
      });
    },
    setManualSequence(data) {
      if (data.item.sequence) {
        this.setCustomerLeadSequence({
          customer_id: data.item.customer_id,
          sequence: data.item.sequence,
        })
          .then((response) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso!",
                text: "Ordem atualizada com sucesso.",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.search(this.currentPage);
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao atualizar a prioridade. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    goToLeadDetails(customer_id) {
      var routeData = this.$router.resolve({
        name: "lead-profile",
        params: { id: customer_id },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    canDrag(event) {
      return (
        (!this.sortField || this.sortField == "sequence") &&
        !this.$refs.filters.name &&
        !this.$refs.filters.createdAt &&
        !this.$refs.filters.recommendedBy &&
        !this.$refs.filters.callReminder &&
        _.isEmpty(this.$refs.filters.goal) &&
        _.isEmpty(this.$refs.filters.origin) &&
        _.isEmpty(this.$refs.filters.status) &&
        _.isEmpty(this.$refs.filters.income) &&
        _.isEqual(this.$refs.filters.monthlySavings, [0, 20000]) &&
        this.$refs.filters.structureOption == structureOptions.OWN &&
        this.structureOption == structureOptions.OWN
      );
    },
    dragDropChange(event) {
      if (this.canDrag()) {
        var customer_id = event.item.attributes["data-pk"].value;
        const sequence = event.newIndex + 1 + (this.currentPage - 1) * 10;
        const customer = this.items[event.newIndex];
        if (customer && customer.sequence) {
          this.setSequence(customer_id, customer.sequence);
        }
      }
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    setSequence(customer_id, sequence) {
      this.setCustomerLeadSequence({
        customer_id,
        sequence,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Ordem atualizada com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.search(this.currentPage);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao atualizar a prioridade. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    saveCallReminder(date, customer_id) {
      this.setCallReminder({
        date,
        customer_id,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Data para lembrar atualizada com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.search(this.currentPage);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao atualizar a prioridade. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    filterByStatus(status) {
      this.$refs.filters.status = [status];
      this.search(1);
    },
    deleteLeadCustomer(id) {
      this.$swal({
        title: "Confirmação",
        text: `Deseja realmente remover? A operação não poderá ser desfeita.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deleteLead({ id })
            .then((response) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso!",
                  text: "Removido com sucesso.",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.search(this.currentPage);
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text:
                    error?.response?.data?.message ||
                    "Ocorreu um erro ao remover o cliente. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    createAppointment(customer_id) {
      this.openNewAppointmentSidebar({
        customer_id,
        saveAction: () => {},
      });
    },
    reorder() {
      this.reorderLeadTable()
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Reordenado com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.search(this.currentPage);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao reordenar a tabela. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    async saveLeadComment(payload) {
      return this.saveComment({
        customer_id: payload.id,
        comment: payload.comment,
      });
    },
    addComment(customer_id) {
      this.openCommentSidebar({
        id: customer_id,
        title: "Adicionar comentário",
        saveAction: this.saveLeadComment,
      });
    },
    clearSearch() {
      this.$refs.filters.name = undefined;
      this.$refs.filters.createdAt = undefined;
      this.$refs.filters.origin = undefined;
      this.$refs.filters.goal = undefined;
      this.$refs.filters.status = undefined;
      this.$refs.filters.income = undefined;
      this.$refs.filters.recommendedBy = undefined;
      this.$refs.filters.callReminder = undefined;
      this.$refs.filters.structureOption = structureOptions.OWN;
      this.$refs.filters.consultant = undefined;
      this.$refs.filters.franchise = undefined;
      this.$refs.filters.occupation = undefined;
      this.$refs.filters.campaign = undefined;
      this.$refs.filters.monthlySavings = [0, 20000];
      this.search(1);
    },
    splitGoals(value) {
      if (value != null) {
        return value.split(";");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.img_action {
  width: 16px;
  height: 16px;
}

#buttons {
  border: 0px;
}

input[type="datetime-local"] {
  width: 180px;
}

.lead-table .phone {
  color: #e3e3e3;
}

.dark-layout .lead-table .phone {
  color: #676d7d;
}

.lead-table .name-content {
  width: 170px;
  display: flex;
  justify-content: center;
}

.lead-table .name-content .name {
  width: auto;
}

.lead-table .name-content .name:hover {
  background-color: #f26237;
}

.lead-table .name-content .whats {
  width: 27px;
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

.lead-table .name-content .whats:hover {
  background-color: #f26237;
}

.lead-table .btn-group .btn {
  border: unset !important;
}

@media (max-width: 768px) {
  .search-buttons-section {
    flex-direction: column-reverse;
  }

  .search-button {
    width: 100%;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

table #buttons {
  width: 40px;
}

.b-table-sticky-column:last-child {
  right: 0;
}

.lead-table thead th {
  white-space: nowrap !important;
}
</style>
